import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import i18next from 'i18next';
import { batch } from 'react-redux';

import { RootState, AppThunk } from '../store';
import { IUserUpdates } from '@api/v1/user/user-updates';
import storage from '@storage/index';
import { IProfileUserBlocklist, UpdateProfileRequest } from '@api/v1/profile/profile';
import { IUserItem } from '@models/user-item';
import { v1 } from '@api/v1';
import { IDropdownItem } from '@api/v1/identity/identity';
import { ELoadingStatus } from '@http/enums';

interface IProfileState {
  error?: string;
  loading: ELoadingStatus;
  logout: boolean;
  updated: boolean;
  data: IUserItem;
}

const initialState: IProfileState = {
  loading: ELoadingStatus.Idle,
  logout: false,
  updated: false,
  data: {},
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = {};
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loading = action.payload;
    },
    setData: (state, action: PayloadAction<IUserItem>) => {
      const newData = { ...state.data, ...action.payload };
      if (!isEqual(newData, state.data)) {
        state.data = newData;
      }
    },
    setAvatarUrl: (state, action: PayloadAction<string | undefined>) => {
      state.data.avatarUrl = action.payload;
    },
    setNickname: (state, action: PayloadAction<string | undefined>) => {
      state.data.userName = action.payload;
    },
    setAbout: (state, action: PayloadAction<string | undefined>) => {
      state.data.about = action.payload;
    },
    setEmployeeFirstName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.firstName = action.payload;
    },
    setEmployeeLastName: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.lastName = action.payload;
    },
    setEmployeeEducationalInstitution: (state, action: PayloadAction<string | undefined>) => {
      state.data.employeeProfile = state.data.employeeProfile ?? {};
      state.data.employeeProfile.educationalInstitution = action.payload;
    },
    setReferenceVkontakte: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.vkontakte = action.payload;
    },
    setReferenceOdnoklassniki: (state, action: PayloadAction<string | undefined>) => {
      state.data.reference = state.data.reference ?? {};
      state.data.reference.odnoklassniki = action.payload;
    },
    setUserUpdates: (state, action: PayloadAction<IUserUpdates>) => {
      state.data.userUpdates = action.payload;
    },
    setProfileUpdated: (state, action: PayloadAction<boolean>) => {
      state.updated = action.payload;
    },
    setBLocklistLoading: (state, action: PayloadAction<boolean>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.loading = action.payload;
    },
    setBlocklistUsers: (state, action: PayloadAction<IProfileUserBlocklist>) => {
      state.data.blocklist = action.payload;
    },
    removeUserFromBlocklist: (state, action: PayloadAction<string>) => {
      state.data.blocklist = state.data.blocklist ?? {};
      state.data.blocklist.items =
        state.data.blocklist?.items!.filter((i: any) => i.nickName !== action.payload) || [];
    },
    setPrivacyList: (state, action: PayloadAction<IDropdownItem[]>) => {
      state.data.privacyList = action.payload;
    },
  },
});

const {
  setProfileUpdated,
  setError,
  setLoading,
  setData,
  setNickname,
  setUserUpdates,
  setBlocklistUsers,
  setBLocklistLoading,
  setPrivacyList,
} = slice.actions;

const profile = {
  ...slice.actions,
  selectProfileUpdated: (state: RootState) => state.profile.updated,
  selectError: (state: RootState) => state.profile.error,
  selectLoading: (state: RootState) => state.profile.loading,
  selectData: createSelector(
    (state: RootState) => state.profile,
    (profileState) => profileState.data,
  ),
  selectAvatarUrl: (state: RootState) => state.profile.data.avatarUrl,
  selectUserName: (state: RootState) => state.profile.data.userName,
  selectUserEmail: (state: RootState) => state.profile.data.email,
  selectBlocklistItems: (state: RootState) => state.profile.data.blocklist?.items,
  getPrivacyList: (): AppThunk => async (dispatch) => {
    try {
      const response = await v1.identity.dropdown.get('SharingPermissions');

      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
      }

      const items = response.items as IDropdownItem[];

      dispatch(setPrivacyList(items as IDropdownItem[]));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      dispatch(setError(errorMessage));
    }
  },
  loadData: (): AppThunk => async (dispatch) => {
    dispatch(setError());
    try {
      dispatch(setLoading(ELoadingStatus.Loading));
      const response = await v1.profile.get();

      if (response.errorCode) {
        dispatch(setLoading(ELoadingStatus.Failed));
        if (response.errorCode === 500) {
          dispatch(setError(i18next.t('common__msg_error_request')));
        }
        if (response.errorCode === 15150) {
          dispatch(setError(i18next.t('common__msg_error_need_to_continue_register')));
        } else {
          dispatch(setError(response.errorMsg));
        }
        return;
      }

      dispatch(setData(response));
    } catch {
      dispatch(setLoading(ELoadingStatus.Failed));
    } finally {
      dispatch(setLoading(ELoadingStatus.Succeeded));
    }
  },
  loadUserUpdates: (): AppThunk => async (dispatch) => {
    try {
      const response = await v1.user.updates.get();
      if (response.errorCode) {
        console.error(response.errorMsg);
        return;
      }
      dispatch(setUserUpdates(response));
    } catch (e) {
      console.error(e);
    }
  },
  updateUserProfile:
    (data: UpdateProfileRequest, withUpdate?: boolean): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setLoading(ELoadingStatus.Loading));

        const response = await v1.profile.put(data);

        if (response.errorCode) {
          dispatch(setLoading(ELoadingStatus.Failed));
          dispatch(
            setError(
              response.errorCode === 2090
                ? i18next.t('common__msg_error_nickname_already_exists')
                : response.errorMsg,
            ),
          );

          return;
        }

        batch(() => {
          dispatch(storage.profile.setData(data));
          dispatch(storage.identity.setNickname(response.userName));
          dispatch(setNickname(response.userName));
          dispatch(setLoading(ELoadingStatus.Succeeded));
        });
        withUpdate && dispatch(setProfileUpdated(true));
      } catch (e) {
        console.error(e);
        dispatch(setLoading(ELoadingStatus.Failed));
      }
    },
  loadBlocklist: (): AppThunk => async (dispatch) => {
    dispatch(setBLocklistLoading(true));
    try {
      const response = await v1.profile.blocklist.get();

      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        return;
      }
      dispatch(setBlocklistUsers(response));
    } finally {
      dispatch(setBLocklistLoading(false));
    }
  },
};

export const profileReducer = slice.reducer;
export default profile;
