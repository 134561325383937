export enum EDifficulty {
  Easy = 0,
  Normal = 1,
  Hard = 2,
}

export enum EMimeType {
  OctetStream = 'OctetStream',
}

export enum EMediaType {
  Image = 'Image',
  Video = 'Video',
  Presentation = 'Presentation',
}

export enum EProjectModerationStatus {
  Created,
  Published,
  Blocked,
  Pending,
  InProcessing,
  ManualModeration,
  AutoModeration,
  Error,
  Deleted,
}

export enum EProjectSortingOrder {
  Default = 'Default',
  Recommended = 'Recommended',
  MostLiked = 'MostLiked',
  MostPopular = 'MostPopular',
}

export enum EEventType {
  Contest = 'Contest',
  Festival = 'Festival',
}

export enum EEventState {
  Draft = 'Draft',
  ComingSoon = 'ComingSoon',
  Active = 'Active',
  Summarizing = 'Summarizing',
  Completed = 'Completed',
  Archived = 'Archived',
}

export enum EBannerType {
  Main = 'Main',
  MainSquare = 'MainSquare',
  Prizes = 'Prizes',
}

export enum EImageFormat {
  Undefined,
  Jpeg,
  Png,
  Gif,
  Heic,
  Heif,
}

export enum ESkillSortingType {
  Round = 'Round',
  Popular = 'Popular',
  New = 'New',
}

export enum EChallengeSortingType {
  Round = 'Round',
  Popular = 'Popular',
  New = 'New',
  Easy = 'Easy',
  Hard = 'Hard',
  Fast = 'Fast',
  Patience = 'Patience',
}

export enum ENotificationType {
  Subscribe = 0,
  LikeProject = 1,
  Comment = 2,
  Experience = 3,
  Skill = 4,
  LikeComment = 5,
  Achievement = 6,
  Level = 7,
  ChallengeChanging = 8,
  Blocking = 9,
  AutoLock = 10,
  Unblocking = 11,
  Quiz = 12,
}

export enum ActionButtonState {
  Active = 'Active',
  Hidden = 'Hidden',
  Error = 'Error',
}

export enum EEventUserRatingType {
  College = 'College',
  Default = 'Default',
  General = 'General',
  Location = 'Location',
  School = 'School',
  Subdivision = 'Subdivision', // level === 0
  Subdivision1 = 'Subdivision1', // level > 0
  Top100 = 'Top100',
  University = 'University',
}

export enum EChallengeState {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  IN_PROCESSING = 'InProcessing',
  IN_ARCHIVE = 'InArchive',
  PUBLISHED = 'Published',
  ERROR = 'Error',
}

export enum ESkillState {
  IN_ARCHIVE = 'InArchive',
  PUBLISHED = 'Published',
}

export enum EProjectCreateMode {
  CREATE = 'create',
  CREATE_WITHOUT_CHALLENGE = 'create-without-challenge',
  EDIT = 'edit',
}

export enum EChallengeSpeed {
  Fast = 0,
  Patience = 1,
}

export enum ECreateProjectErrorType {
  // media without request
  NO_MEDIA = 'no-media',
  NO_NAME = 'no_name',
  LONG_DESCRIPTION = 'long-description',
  TOO_BIG = 'too-big',
  TOO_BIG_VIDEO_FILE = 'too-big-video-file',
  // media with request
  LONG_VIDEO = 'long-video',
  SMALL_IMAGE = 'small-image',
  MAX_MEDIA = 'max-media',
  MAX_PROJECTS = 'max-projects',
  ASPECT_RATIO = 'aspect-ratio',
  SERVER_ERROR = 'server-error',
  DUPLICATE_MEDIA = 'duplicate-media',
  // presentation without request
  TOO_BIG_PRESENTATION = 'too-big-presentation',
  WRONG_PRESENTATION_TYPE = 'wrong-presentation-type',
}

export enum EContentTypes {
  ApplicationJSON = 'application/json',
  ApplicationXWWWFormUrlencoded = 'application/x-www-form-urlencoded',
  MultipartFormData = 'multipart/form-data',
  VideoMP4 = 'video/mp4',
  VideoMov = 'video/quicktime',
  ImageJPEG = 'image/jpeg',
  ImagePNG = 'image/png',
  Pdf = 'application/pdf',
  Ppt = 'application/vnd.ms-powerpoint',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum ECompanyNames {
  Default = 'default',
  Demo = 'demo',
  DemoEnglish = 'demoEnglish',
  Highland = 'highland',
  Hmao = 'hmao',
  Korobka = 'korobka',
  Magnit = 'magnit',
  Nornickel = 'nornickel',
  Perekrestok = 'perekrestok',
  Pyatorochka = 'pyatorochka',
  Round = 'round',
  Sfr = 'sfr',
}

export enum EShowEventType {
  AllPeriod = 'AllPeriod',
  OnlyBeginDate = 'OnlyBeginDate',
}

export enum ELoadingStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum EAchievementItemType {
  Event = 'event',
  Interest = 'interest',
}

export enum ELanguage {
  RU = 'RU',
  EN = 'EN',
}

export enum EDocumentType {
  LicensingPolicy = 'licensingPolicy',
  PublicationRules = 'publicationRules',
  ExternalLibraries = 'externalLibraries',
}

export enum ESocial {
  Vkontakte = 'Vkontakte',
  Odnoklassniki = 'Odnoklassniki',
  Google = 'Google',
  Eljur = 'Eljur',
  Mail = 'Mail',
}

export enum EQuizPage {
  QuizInfo = 'QuizInfo',
  QuizQuestion = 'QuizQuestion',
  QuizResult = 'QuizResult',
}

export enum ERegistrationReferralType {
  ImmediatelyAfterRegistration = 'ImmediatelyAfterRegistration',
  AfterApprovalFirstProject = 'AfterApprovalFirstProject',
}

export enum EQuizType {
  Open = 'Open',
  Closed = 'Closed',
}

export enum EQuizQuestionType {
  // open
  Open = 'Open',
  // closed
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  Ordering = 'Ordering',
  Matching = 'Matching',
}

export enum EQuizQuestionOptionContentType {
  TextOnly = 'TextOnly',
  ImageOnly = 'ImageOnly',
  TextAndImage = 'TextAndImage',
}

export enum EStateComment {
  Deleted = 'Deleted',
  Published = 'Published',
}

export enum EPrivacySettingsName {
  Nobody = 'Nobody',
  Subscribers = 'Subscribers',
  Subscriptions = 'Subscriptions',
  SubscribersSubscriptions = 'SubscribersSubscriptions',
  AllUsers = 'AllUsers',
}

export enum EPrivacyFields {
  SharingPermissionsProject = 'sharingPermissionsProject',
  SharingPermissionsProfile = 'sharingPermissionsProfile',
}

export enum ERegistrationType {
  Open = 'Open',
  Closed = 'Closed',
  Sync = 'Sync',
}

export enum EQuizResultStatus {
  Validation = 'Validation',
  Passed = 'Passed',
  Failed = 'Failed',
}

export enum ERole {
  NotSelected = 'NotSelected',
  Student = 'Student',
  Pupil = 'Pupil',
  Parent = 'Parent',
  Teacher = 'Teacher',
  Employee = 'Employee',
}

export enum EEducationalInstitutionType {
  NotSelected = 'NotSelected',
  School = 'School',
  College = 'College',
  University = 'University',
}

export enum ESignUpType {
  signUpWithSMS = 'signUpWithSMS',
  signUpWithSMSOpen = 'signUpWithSMSOpen',
  signUpWithEmail = 'signUpWithEmail',
  signUpWithEmailOpen = 'signUpWithEmailOpen',
  signUpWithVK = 'signUpWithVK',
  signUpWithGoogle = 'signUpWithGoogle',
  signUpWithEljur = 'signUpWithEljur',
  signUpFromInviteSMS = 'signUpFromInviteSMS',
  signUpFromInviteEmail = 'signUpFromInviteEmail',
}

export enum EGender {
  Male = 'Male',
  Female = 'Female',
  NotSelected = 'NotSelected',
}

export enum EAuthHomeScreen {
  Default = 'Default',
  Nornickel = 'Nornickel',
}

export enum EUnitKeys {
  unitZeroBytes = 'zeroBytes',
  unitB = 'b',
  unitKb = 'kb',
  unitMb = 'mb',
}

export enum EInstitutionType {
  University = 'University',
  College = 'College',
  School = 'School',
}

export enum EPresentationCardVariant {
  stable = 'stable',
  variable = 'variable',
}

export enum EPlatform {
  Ios = 'ios',
  Web = 'web',
}

export enum ESupportType {
  Carrot = 'Carrot',
  Email = 'Email',
  Vk = 'Vk',
}

export enum EAdditionFields {
  Category = 'Category',
  Direction = 'Direction',
  PlaceOfStudy = 'PlaceOfStudy',
}

export enum EMobileOS {
  Android = 'Android',
  IOS = 'iOS',
  Unknown = 'unknown',
}
